import React, { useState, useEffect, useRef } from 'react';
import { Button, FormLabel, FormControl, Flex, Text, Box, Grid, GridItem, IconButton, Image, Switch } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { CloseIcon } from '@chakra-ui/icons';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import {
    uploadPhotoProduct,
    useChangeStatusProductMutation,
    useCreateProductMutation,
    useGetColorForProdMutation,
    useGetSizeForProdMutation,
    useQueryGetCatForProduct,
    useQueryGetProductDetail,
    useUpdateProductMutation,
} from 'services/product';
import { mappingOptionSelect } from 'utils/mapping';
import { ProductFormValidate } from 'utils/validation';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { ROOT_API } from 'constants/common';
import { gameOption } from 'utils/contants';
import { useCreateBookMakerMutation, useCreateGameMutation, useQueryGetDetailBookMaker, useUpdateBookMakerMutation } from 'services/game';

export default function BookMakerForm() {
    const params = useParams();
    const { id, bookMarkerId } = params || {};
    const history = useHistory();
    const [categoryOption, setCategoryOption] = useState([]);
    const [sizeOption, setSizeOption] = useState([]);
    const [colorOption, setColorOption] = useState([]);
    const [error, setError] = useState({
        thumbnailFile: null,
    });
    const [files, setFiles] = useState([]);
    const [isDragEnter, setIsDragEnter] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDragEnter: () => {
            setIsDragEnter(true);
        },
        onDragLeave: () => {
            setIsDragEnter(false);
        },
        onDrop: (acceptedFiles) => {
            setIsDragEnter(false);
            setError({ thumbnailFile: null });

            // Chỉ lấy file đầu tiên trong danh sách
            const [firstFile] = acceptedFiles;

            // Nếu đã có file trước đó, xóa nó đi
            setFiles([
                {
                    id: nanoid(),
                    preview: URL.createObjectURL(firstFile),
                    file: firstFile,
                },
            ]);
        }
        // onDrop: acceptedFiles => {
        //     setIsDragEnter(false);
        //     setError({ thumbnailFile: null });
        //     setFiles(prev => [
        //         ...prev,
        //         ...acceptedFiles.map(file => ({
        //             id: nanoid(),
        //             preview: URL.createObjectURL(file),
        //             file,
        //         })),
        //     ]);
        // },
    });
    const { data: bookmakerDetailData } = useQueryGetDetailBookMaker(id, {
        enabled: !!id,
      });
    const createBookMakerMutation = useCreateBookMakerMutation();
    const updateBookMakerMutation = useUpdateBookMakerMutation();

    const { control, handleSubmit, reset, setValue } = useForm({
        // resolver: yupResolver(ProductFormValidate),
        defaultValues: {
            name: '',
            url: '',
            rate: ''
        },
    });
    // const { fields, append, remove } = useFieldArray({
    //     control,
    //     name: 'variants',
    // });

    useEffect(() => {
        const bookmakerDetail = bookmakerDetailData?.data;
        if (!isEmpty(bookmakerDetail)) {
          reset({
            name: bookmakerDetail.name,
            url:bookmakerDetail.url,
            rate:bookmakerDetail.rate,
          });
          setFiles([
            {
              id: nanoid(),
              preview: ROOT_API + '/' + bookmakerDetail?.logo,
              file: bookmakerDetail.logo,
            },
          ]);
        }
      }, [bookmakerDetailData]);

    // const handleCategorySelect = e => {
    //     if (!e) {
    //         setSizeOption([]);
    //         setColorOption([]);
    //         setValue('size', 'undefined');
    //         setValue('color', 'undefined');
    //         return;
    //     }
    //     handleSizeAndColorOption(e.value);
    // };

    // const onUploadPhoto = async () => {
    //     const formData = new FormData();
    //     const filesUpload = files.filter(item => item.file);
    //     const filesExist = files.filter(item => !item.file).map(item => item.path);
    //     let pathFiles = [];

    //     if (!isEmpty(filesUpload)) {
    //         filesUpload.map(item => {
    //             formData.append('thumbnailFiles', item.file);
    //         });

    //         const response = await uploadPhotoProduct(formData);

    //         pathFiles = response?.data;
    //     }

    //     return [...filesExist, ...pathFiles];
    // };

    const onSubmit = async dataForm => {
        // console.log(dataForm);
        // if (isEmpty(files)) {
        //     setError({ thumbnailFile: 'Tải lên 1 hình ảnh' });
        //     return;
        // }



        // const photosPath = await onUploadPhoto();
        const formData = new FormData();
        formData.append('name', dataForm.name);
        formData.append('url', dataForm.url);
        formData.append('rate', dataForm.rate);
        formData.append('logoFile', files[0].file);

        if (!!id) {
            formData.append('id', id);
            updateBookMakerMutation.mutate(formData, {
              onSuccess: () => {
                toast.showMessageSuccess('Cập nhật nhà cái thành công');
                history.push('/admin/book-maker');
              },
              onError: () => {
                toast.showMessageError('Cập nhật nhà cái thất bại');
              },
            });
      
            return;
          }
        createBookMakerMutation.mutate(formData, {
            onSuccess: (res) => {
                toast.showMessageSuccess('Tạo nhà cái thành công');
                history.push('/admin/book-maker');
            },
            onError: () => {
                toast.showMessageError('Tạo nhà cái thất bại');
            },
        });
    };

    const onRemove = index => {
        setFiles(prev => prev.filter((i, idX) => idX !== index));
    };

    return (
        <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
            <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
                <CardHeader p="6px 0px 22px 0px">
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontSize="xl" fontWeight="bold">
                            {`${id ? 'Cập nhập' : 'Tạo'} nhà cái`}
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody pb={4}>
                    <form>
                        <InputController control={control} name="name" label="Tên nhà cái" isRequired styleContainer={{ pt: '4' }} />
                        <InputController control={control} name="url" label="Url" styleContainer={{ pt: '4' }} />
                        <InputController control={control} name="rate" label="Đánh giá" styleContainer={{ pt: '4' }} />
                        <FormControl pt={4}>
                            <FormLabel minW="150px">Ảnh</FormLabel>
                            <Box
                                {...getRootProps()}
                                cursor="pointer"
                                borderWidth="1px"
                                borderStyle="dashed"
                                borderRadius="12px"
                                padding="55px"
                                textAlign="center"
                                height="210px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <input {...getInputProps()} />
                                <BsUpload size={40} />
                                {isDragEnter ? (
                                    <p>Thả ảnh của bạn</p>
                                ) : (
                                    <>
                                        <p>
                                            <Text as="span" color="red.400">
                                                Tải tập tin lên
                                            </Text>
                                            &nbsp;hoặc kéo và thả
                                        </p>
                                    </>
                                )}
                            </Box>
                            <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                                {files?.map((file, index) => (
                                    <GridItem w={'100%'} key={file.id} position="relative">
                                        <Image src={file.preview} w="full" h="full" objectFit="contain" />
                                        <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                                            <BsXCircle size={16} color="red" />
                                        </IconButton>
                                    </GridItem>
                                ))}
                            </Grid>
                            {!!error.thumbnailFile && (
                                <Text pt={1} color={'red.500'} fontSize="13px">
                                    {error.thumbnailFile}
                                </Text>
                            )}
                        </FormControl>
                    </form>
                    <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
                        <Button
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            Hủy
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            isLoading={createBookMakerMutation.isPending || updateBookMakerMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {!!id ? 'Cập nhập ' : 'Tạo'}
                        </Button>
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    );
}
