import React, { useState, useEffect, useRef } from 'react';
import { Button, FormLabel, FormControl, Flex, Text, Box, Grid, GridItem, IconButton, Image, Switch } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { toast } from 'components/Toast';
import { ROOT_API } from 'constants/common';
import { useCreateCodeMutation, useCreateGameRoomMutation, useQueryGetDetailGameRoom, useUpdateGameRoomMutation } from 'services/game';

export default function CodeCreate() {
    const history = useHistory();
    const createCodeMutation = useCreateCodeMutation();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            number: 1,
        },
    });

    const onSubmit = async dataForm => {
        createCodeMutation.mutate(dataForm, {
          onSuccess: res => {
            toast.showMessageSuccess('Tạo mã tín dụng thành công');
            history.push('/admin/code');
          },
          onError: () => {
            toast.showMessageError('Tạo mã tín dụng thất bại');
          },
        });
    };

    const onRemove = index => {
        setFiles(prev => prev.filter((i, idX) => idX !== index));
    };

    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
          <CardHeader p="6px 0px 22px 0px">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="xl" fontWeight="bold">
                Tạo mã tín dụng
              </Text>
            </Flex>
          </CardHeader>
          <CardBody pb={4}>
            <form>
              <InputController type="number" control={control} name="number" label="Số lượng xu" isRequired styleContainer={{ pt: '4' }} />
            </form>
            <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Hủy
              </Button>
              <Button colorScheme="blue" ml={3} isLoading={createCodeMutation.isPending} onClick={handleSubmit(onSubmit)}>
                Tạo
              </Button>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    );
}
