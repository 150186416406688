import { useState } from 'react';
import { Button, Flex, Stack, Table, Text, useColorModeValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import CodeTable from './components/CodeTable';
import { useDropCodeMutation, useQueryGetListCode } from 'services/game';
import { toast } from 'components/Toast';

export default function Code() {
    const history = useHistory();
    const textColor = useColorModeValue('gray.700', 'white');
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { data: codeData, refetch } = useQueryGetListCode(filter);
    const deleteMutation = useDropCodeMutation();

    const handleDelete = () => {
      const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa tất cả mã tín dụng này không?');
      if (!confirmDelete) {
        return;
      }

      deleteMutation.mutate(
        { },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa tất cả mã tín dụng thành công');
            refetch?.();
          },
          onError: () => {
            toast.showMessageError('Xóa tất cả mã tín dụng thất bại');
            refetch?.();
          },
        }
      );
    };
    return (
      <>
        <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
          <Card p="16px" mb="24px" bg="#fff">
            <CardHeader p="12px 5px" mb="12px">
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Flex direction="column" gap={'30px'}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                      Mã Tín Dụng
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={2}>
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      history.push('/admin/code/create');
                    }}
                  >
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                  {/* <Button bg="red" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={handleDelete}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Xóa mã
                    </Text>
                  </Button> */}
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody overflowX="auto">
              <Stack overflow={'auto'}>
                <CodeTable data={codeData?.data || []} refetch={refetch} />
              </Stack>
              <Flex justifyContent={'flex-end'}>
                {!isEmpty(codeData?.data) && (
                  <Pagination
                    page={codeData?.pagination?.page}
                    pageLength={codeData?.pagination?.pageSize}
                    totalRecords={codeData?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    }}
                  />
                )}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </>
    );
}
