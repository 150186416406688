import React from 'react';
import { IoBook, IoListCircleOutline } from 'react-icons/io5';
import { BiFoodMenu } from 'react-icons/bi';
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Size from 'views/Dashboard/Size';
import Colors from 'views/Dashboard/Colors';
import Product from 'views/Dashboard/Product';
import ProductForm from 'views/Dashboard/Product/components/ProductForm';
import Category from 'views/Dashboard/Category/Category';
import Member from 'views/Dashboard/Members'
import User from 'views/Dashboard/User';
import Games from 'views/Dashboard/games';
import BookMaker from 'views/Dashboard/BookMaker/BookMaker';
import Form from 'views/Dashboard/BookMaker/components/Form';
import Rooms from 'views/Dashboard/Table';
import Result from 'views/Dashboard/Result';
import GameForm from 'views/Dashboard/games/components/Form';
import BookMakerForm from 'views/Dashboard/BookMaker/components/BookMarkerCreate';
import GameRoom from 'views/Dashboard/GameRoom/GameRoom';
import GameRoomCreate from 'views/Dashboard/GameRoom/components/GameRoomCreate';
import Code from 'views/Dashboard/Code/Code';
import CodeCreate from 'views/Dashboard/Code/components/CodeCreate';
// import Lobby from 'views/Dashboard/Lubby/Lobby';
// import LoppyCreateForm from 'views/Dashboard/Lubby/components/LoppyCreate';

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Trang chủ',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/members',
    name: 'Quản lý thành viên',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Member,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/users',
    name: 'Quản lý quản trị viên',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: User,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/game-room/create',
    name: 'Sảnh game',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: GameRoomCreate,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/game-room/:id',
    name: 'Sảnh game',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: GameRoomCreate,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/game-room',
    name: 'Sảnh game',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: GameRoom,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/book-maker/create',
    name: 'Nhà cái',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: BookMakerForm,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/book-maker/:id',
    name: 'Nhà cái',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: BookMakerForm,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/book-maker',
    name: 'Nhà cái',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: BookMaker,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/code/create',
    name: 'Mã tín dụng',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: CodeCreate,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/code',
    name: 'Mã tín dụng',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Code,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  // {
  //   path: '/lobby/create',
  //   name: 'Sảnh game',
  //   icon: <BiFoodMenu color="inherit" />,
  //   layout: '/admin',
  //   component: LoppyCreateForm,
  //   redirect: true,
  //   role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  // },
  // {
  //   path: '/lobby/:id',
  //   name: 'Sảnh game',
  //   icon: <BiFoodMenu color="inherit" />,
  //   layout: '/admin',
  //   component: LoppyCreateForm,
  //   redirect: true,
  //   role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  // },
  // {
  //   path: '/lobby',
  //   name: 'Sảnh game',
  //   icon: <BiFoodMenu color="inherit" />,
  //   layout: '/admin',
  //   component: Lobby,
  //   role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  // },
  {
    path: '/games/:bookMarkerId/create',
    name: 'Trò chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: GameForm,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/games/:id',
    name: 'Trò chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Games,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/games',
    name: 'Trò chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Games,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/room/:id',
    name: 'Bàn chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Rooms,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/result/:id',
    name: 'Bàn chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Result,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/result',
    name: 'Kết quả',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Result,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/room',
    name: 'Bàn chơi',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Rooms,
    redirect: true,
    role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  },
  {
    path: '/games/create',
    name: 'Tạo trò chơi',
    icon: <IoListCircleOutline size={20} />,
    redirect: true,
    layout: '/admin',
    component: Member,
    role: [Roles.ADMIN],
  },
  // {
  //   path: '/games',
  //   name: 'Trò chơi',
  //   icon: <BiFoodMenu color="inherit" />,
  //   layout: '/admin',
  //   component: Games,
  //   role: [Roles.ADMIN, Roles.OWNER, Roles.AGENT],
  // },
  {
    path: '/sign-in',
    layout: '/auth',
    redirect: true,
    component: SignIn,
  },
  {
    path: '/sign-up',
    layout: '/auth',
    redirect: true,
    component: SignUp,
  },
  // {
  //   name: 'TÀI KHOẢN',
  //   category: 'account',
  //   rtlName: 'صفحات',
  //   state: 'pageCollapse',
  //   views: [
  //     {
  //       path: '/profile',
  //       name: 'Thông tin cá nhân',
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       layout: '/admin',
  //       component: Profile,
  //       role: [Roles.ADMIN, Roles.USER, Roles.GUEST, Roles.OWNER, Roles.AGENT],
  //     },
  //     {
  //       path: '/sign-in',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignIn,
  //     },
  //     {
  //       path: '/sign-up',
  //       layout: '/auth',
  //       redirect: true,
  //       component: SignUp,
  //     },
  //   ],
  // },
];

export default dashRoutes;
