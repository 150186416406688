import React, { useEffect, useState } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Switch,
    FormControl,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react'
import { ModalType } from 'constants/common';
import { Controller, useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { useCreateCoinMutation, useRedeemCoinMutation } from 'services/member';
import { toast } from 'components/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { RedeemCoinFormValidate } from 'utils/validation';
const ModalDeleteCoin = ({ isOpen, onClose, refetch, id }) => {
    const cancelRef = React.useRef();
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            balance: "",
            reset: false
        }
    })
    const redeemCoinMutation = useRedeemCoinMutation()
    const handleSuccess = () => {
        toast.showMessageSuccess(`Thu hồi xu thành công`);
        refetch?.();
        onClose(ModalType.Delete);
    };

    const handleError = error => {
        toast.showMessageError(
            error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Thu hồi xu thất bại`
        );
    };
    const onSubmit = async values => {
        redeemCoinMutation.mutate(
            { ...values, id },
            {
                onSuccess: () => handleSuccess(),
                onError: error => handleError(error),
            }
        );
    };
    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    onClose(ModalType.Delete);
                }}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
                    <AlertDialogHeader textTransform="uppercase">Thu hồi xu</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <InputController
                            label="Xu"
                            name="balance"
                            control={control}
                        />
                        <Controller
                            name={'reset'}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl display="flex" flexDirection="column" mt={4}>
                                    <label>Thu hồi tất cả</label>
                                    <Switch
                                        id="reset-switch"
                                        name="reset"
                                        colorScheme="blue"
                                        size="lg"
                                        isChecked={field?.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                        mt={1}
                                    />
                                </FormControl>
                            )}
                        />

                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                onClose(ModalType.Delete);
                            }}
                        >
                            Huỷ bỏ
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            // isLoading={createUserByRoleMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Tiếp tục
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default ModalDeleteCoin;
