import React, { useState, useEffect, useRef } from 'react';
import { Button, FormLabel, FormControl, Flex, Text, Box, Grid, GridItem, IconButton, Image, Switch } from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useHistory, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import InputController from 'components/Form/InputController';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { toast } from 'components/Toast';
import { ROOT_API } from 'constants/common';
import { useCreateGameRoomMutation, useQueryGetDetailGameRoom, useUpdateGameRoomMutation } from 'services/game';

export default function GameRoomCreate() {
    const params = useParams();
    const { id } = params || {};
    const history = useHistory();
    const [error, setError] = useState({
        thumbnailFile: null,
    });
    const [files, setFiles] = useState([]);
    const [isDragEnter, setIsDragEnter] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDragEnter: () => {
            setIsDragEnter(true);
        },
        onDragLeave: () => {
            setIsDragEnter(false);
        },
        onDrop: (acceptedFiles) => {
            setIsDragEnter(false);
            setError({ thumbnailFile: null });

            // Chỉ lấy file đầu tiên trong danh sách
            const [firstFile] = acceptedFiles;

            // Nếu đã có file trước đó, xóa nó đi
            setFiles([
                {
                    id: nanoid(),
                    preview: URL.createObjectURL(firstFile),
                    file: firstFile,
                },
            ]);
        }
    });
    const { data: gameRoomDetailData } = useQueryGetDetailGameRoom(id, {
      enabled: !!id,
    });
    const createGameRoomMutation = useCreateGameRoomMutation();
    const updateGameRoomMutation = useUpdateGameRoomMutation();

    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        const gameRoomDetail = gameRoomDetailData?.data;
        if (!isEmpty(gameRoomDetail)) {
          reset({
            name: gameRoomDetail.name,
            url:gameRoomDetail.url,
            rate:gameRoomDetail.rate,
          });
          setFiles([
            {
              id: nanoid(),
              preview: ROOT_API + '/' + gameRoomDetail?.logo,
              file: gameRoomDetail.logo,
            },
          ]);
        }
      }, [gameRoomDetailData]);

    const onSubmit = async dataForm => {
        // const photosPath = await onUploadPhoto();
        const formData = new FormData();
        formData.append('name', dataForm.name);
        formData.append('logoFile', files[0].file);

        if (!!id) {
            formData.append('id', id);
            updateGameRoomMutation.mutate(formData, {
              onSuccess: () => {
                toast.showMessageSuccess('Cập nhật sảnh game thành công');
                history.push('/admin/game-room');
              },
              onError: () => {
                toast.showMessageError('Cập nhật sảnh game thất bại');
              },
            });
      
            return;
          }
        createGameRoomMutation.mutate(formData, {
            onSuccess: (res) => {
                toast.showMessageSuccess('Tạo sảnh game thành công');
                history.push('/admin/game-room');
            },
            onError: () => {
                toast.showMessageError('Tạo sảnh game thất bại');
            },
        });
    };

    const onRemove = index => {
        setFiles(prev => prev.filter((i, idX) => idX !== index));
    };

    return (
        <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
            <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bg="white">
                <CardHeader p="6px 0px 22px 0px">
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontSize="xl" fontWeight="bold">
                            {`${id ? 'Cập nhập' : 'Tạo'} sảnh game`}
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody pb={4}>
                    <form>
                        <InputController control={control} name="name" label="Tên sảnh game" isRequired styleContainer={{ pt: '4' }} />
                        <FormControl pt={4}>
                            <FormLabel minW="150px">Ảnh</FormLabel>
                            <Box
                                {...getRootProps()}
                                cursor="pointer"
                                borderWidth="1px"
                                borderStyle="dashed"
                                borderRadius="12px"
                                padding="55px"
                                textAlign="center"
                                height="210px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <input {...getInputProps()} />
                                <BsUpload size={40} />
                                {isDragEnter ? (
                                    <p>Thả ảnh của bạn</p>
                                ) : (
                                    <>
                                        <p>
                                            <Text as="span" color="red.400">
                                                Tải tập tin lên
                                            </Text>
                                            &nbsp;hoặc kéo và thả
                                        </p>
                                    </>
                                )}
                            </Box>
                            <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                                {files?.map((file, index) => (
                                    <GridItem w={'100%'} key={file.id} position="relative">
                                        <Image src={file.preview} w="full" h="full" objectFit="contain" />
                                        <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                                            <BsXCircle size={16} color="red" />
                                        </IconButton>
                                    </GridItem>
                                ))}
                            </Grid>
                            {!!error.thumbnailFile && (
                                <Text pt={1} color={'red.500'} fontSize="13px">
                                    {error.thumbnailFile}
                                </Text>
                            )}
                        </FormControl>
                    </form>
                    <Flex pt={6} alignItems="flex-end" justifyContent="flex-end">
                        <Button
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            Hủy
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            isLoading={createGameRoomMutation.isPending || updateGameRoomMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {!!id ? 'Cập nhật ' : 'Tạo'}
                        </Button>
                    </Flex>
                </CardBody>
            </Card>
        </Flex>
    );
}
