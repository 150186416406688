import { useState } from 'react';
import { Button, Flex, Stack, Table, Text, useColorModeValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { useQueryGetListBookMaker } from 'services/game';
import BookMakerTable from './components/BookMakerTable';

export default function BookMaker() {
    const history = useHistory();
    const textColor = useColorModeValue('gray.700', 'white');
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const { data: bookMakerData, refetch } = useQueryGetListBookMaker(filter);
    return (
        <>
            <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
                <Card p="16px" mb="24px" bg="#fff">
                    <CardHeader p="12px 5px" mb="12px">
                        <Flex justifyContent={'space-between'}>
                            <Flex direction={'column'}>
                                <Flex direction="column" gap={'30px'}>
                                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                                        Nhà cái
                                    </Text>
                                </Flex>
                            </Flex>
                            <Button
                                bg="#3182ce"
                                color="#fff"
                                _hover={{ bg: '#67a1d7' }}
                                onClick={() => {
                                    history.push('/admin/book-maker/create');
                                }}
                            >
                                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                                    Thêm
                                </Text>
                            </Button>
                        </Flex>
                    </CardHeader>
                    <CardBody overflowX="auto">
                        <Stack overflow={'auto'}>
                            <BookMakerTable data={bookMakerData?.data || []} refetch={refetch} />
                        </Stack>
                        <Flex justifyContent={'flex-end'}>
                            {!isEmpty(bookMakerData?.data) && (
                                <Pagination
                                    page={bookMakerData?.pagination?.page}
                                    pageLength={bookMakerData?.pagination?.pageSize}
                                    totalRecords={bookMakerData?.pagination?.count}
                                    onPageChange={(page, pageLength) => {
                                        setFilter({
                                            ...filter,
                                            pageSize: pageLength,
                                            pageIndex: page - 1,
                                        });
                                    }}
                                />
                            )}
                        </Flex>
                    </CardBody>
                </Card>
            </Flex>
        </>
    );
}
