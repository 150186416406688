import { Box, Checkbox, Flex, IconButton, Image, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import {
    useChangeStatusProductMutation,
    useDeleteProductMutation,
    useSetHotProductMutation,
    useSetNewProductMutation,
} from 'services/product';
import { BiCommentDetail } from 'react-icons/bi';
import { useChangeStatusMutation, useDeleteGameMutation } from 'services/game';
import { STATUS_RESULT } from 'utils/contants';

const ResultTable = ({ data, refetch }) => {
    const history = useHistory();
    const [sorting, setSorting] = useState([]);
    const columnHelper = createColumnHelper();

    const deleteMutation = useDeleteGameMutation();
    const changeStatusMutation = useChangeStatusMutation();
    const handleDelete = game => {
        const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa trò chơi này không?');
        if (!confirmDelete) {
            return;
        }

        deleteMutation.mutate(
            { id: game?._id },
            {
                onSuccess: () => {
                    toast.showMessageSuccess('Xóa trò chơi thành công');
                    refetch?.();
                },
                onError: () => {
                    toast.showMessageError('Xóa trò chơi thất bại');
                    refetch?.();
                },
            }
        );
    };
    const onActionProduct = (roomResult, actionApi, status) => {
        if (roomResult?.status === STATUS_RESULT.PENDING) {
            actionApi.mutate(
                {
                    roomResultId: roomResult?._id,
                    status
                },
                {
                    onSuccess: () => {
                        toast.showMessageSuccess(`Cập nhật kết quả thành công`);
                        refetch?.();
                    },
                    onError: () => {
                        toast.showMessageError(`Cập nhật kết quả thất bại`);
                        refetch?.();
                    },
                }
            );
        }
    };
    const columns = useMemo(
        () => [
            columnHelper.accessor('result', {
                header: 'Dự đoán',
                cell: info => {
                    return (
                        <Box textTransform="uppercase">
                            {info.getValue().tai ? 'Tài' : 'Xỉu'}
                        </Box>
                    );
                },
            }),
            columnHelper.accessor('status', {
                header: 'Kết quả',
                cell: info => (
                    <Flex gap={4}>

                        {/* <Switch
                            size="md"
                            isChecked={info.getValue()}
                            onChange={e => onActionProduct(info?.row?.original?._id, changeStatusMutation)}
                        /> */}
                        <Text>Sai</Text>
                        <Checkbox
                            size="md"
                            isChecked={info.getValue() === STATUS_RESULT.WRONG && true}
                            onChange={() => onActionProduct(info?.row?.original, changeStatusMutation, STATUS_RESULT.WRONG)}
                        />

                        <Checkbox
                            size="md"
                            isChecked={info.getValue() === STATUS_RESULT.CORRECT && true}
                            onChange={() => onActionProduct(info?.row?.original, changeStatusMutation, STATUS_RESULT.CORRECT)}
                        />
                        <Text>Đúng</Text>
                    </Flex>
                ),
            }),
            // columnHelper.accessor('type', {
            //     header: 'Thể loại',
            //     cell: info => <Box>{info.getValue()}</Box>,
            // }),
            columnHelper.accessor('createdAt', {
                header: 'Ngày tạo',
                cell: info => formatDate(info.getValue(), "DD/MM/YYYY HH:mm:ss"),
            }),
            columnHelper.accessor('updatedAt', {
                header: 'Ngày cập nhật',
                cell: info => formatDate(info.getValue(), "DD/MM/YYYY HH:mm:ss"),
            }),
            // columnHelper.accessor('action', {
            //     header: '',
            //     cell: info => (
            //         <Flex alignItems="center" gap={1}>
            //             {/* <IconButton
            //                 bg="transparent"
            //                 onClick={() => {
            //                     history.push(`/admin/table/${info?.row?.original?._id}`);
            //                 }}
            //             >
            //                 <EditIcon cursor="pointer" boxSize={4} />
            //             </IconButton> */}
            //             <IconButton
            //                 bg="transparent"
            //                 onClick={() => {
            //                     history.push(`/admin/room/${info?.row?.original?._id}`);
            //                 }}
            //             >
            //                 <BiCommentDetail cursor="pointer" boxSize={4} />
            //             </IconButton>
            //             <IconButton
            //                 bg="transparent"
            //                 onClick={() => {
            //                     handleDelete(info?.row?.original);
            //                 }}
            //             >
            //                 <DeleteIcon color="red.400" boxSize={4} />
            //             </IconButton>
            //         </Flex>
            //     ),
            // }),
        ],
        [data]
    );

    const table = useReactTable({
        data: data || [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <Th key={header.id} w="120px">
                                {header.isPlaceholder ? null : (
                                    <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                        }[header.column.getIsSorted()] ?? null}
                                    </Box>
                                )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {isEmpty(table.getRowModel().rows) ? (
                    <Tr>
                        <Td textAlign="center" colSpan={10}>
                            Không có dữ liệu
                        </Td>
                    </Tr>
                ) : (
                    table.getRowModel().rows.map(row => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                            ))}
                        </Tr>
                    ))
                )}
            </Tbody>
        </Table>
    );
};

export default ResultTable;
