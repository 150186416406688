import { useMemo, useState } from 'react';
import { Box, Button, Flex, Stack, Switch, Table, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { useCreateResultMutation, useQueryGetListResult } from 'services/game';
import { ModalType } from 'constants/common';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ResultTable from './components/ResultTable';
import CreatePredictResultModal from './components/CreatePredictResult';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'components/Toast';

export default function Result() {
    const params = useParams();
    const { id } = params || {};
    const history = useHistory();
    const textColor = useColorModeValue('gray.700', 'white');
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const createResultMutation = useCreateResultMutation();
    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            roomId: id,
            result: {
                tai: false,
                xiu: false
            },
        },
    });
    const handleSuccess = () => {
        reset();
        toast.showMessageSuccess(`Thêm dự đoán thành công`);
        refetch?.();
        // onClose(ModalType.Add);
    };

    const handleError = error => {
        toast.showMessageError(
            error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Thêm dự đoán thất bại`
        );
    };
    const onSubmit = values => {
        const dataSubmit = {
            ...values,
            result: {
                tai: values.result.xiu,
                xiu: !values.result.xiu,
            },
        };
        createResultMutation.mutate(
            dataSubmit,
            {
                onSuccess: () => handleSuccess(),
                onError: error => handleError(error),
            }
        );
    };
    const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
    const { onClose: onCloseChangeStatusModal, onOpen: onOpenChangeStatusModal } = useDisclosure();
    const {
        isOpen: isOpenViewMemberDetailModal,
        onOpen: onOpenViewMemberDetailModal,
        onClose: onCloseViewMemberDetalModal,
    } = useDisclosure();
    const openModal = useMemo(
        () => ({
            [ModalType.Preview]: onOpenViewMemberDetailModal,
            [ModalType.ChangeStatus]: onOpenChangeStatusModal,
        }),
        [onOpenViewMemberDetailModal, onOpenChangeStatusModal]
    );

    const closeModal = useMemo(
        () => ({
            [ModalType.Add]: onCloseCreateModal,
            [ModalType.Preview]: onCloseViewMemberDetalModal,
            [ModalType.ChangeStatus]: onCloseChangeStatusModal,
        }),
        [onCloseCreateModal, onCloseChangeStatusModal]
    );

    const handleCloseModal = modalType => {
        closeModal?.[modalType]?.();
    };
    const { data: resultData, refetch } = useQueryGetListResult(id, filter);
    return (
        <>
            <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
                <Card p="16px" mb="24px" bg="#fff">
                    <CardHeader p="12px 5px" mb="12px">
                        <Flex justifyContent={'space-between'}>
                            <Flex direction={'column'}>
                                <Flex direction="column" gap={'30px'}>
                                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                                        Kết quả dự đoán
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex alignItems="center" justifyContent="center" gap={10}>
                                <Controller
                                    control={control}
                                    name="result.xiu"
                                    render={({ field }) => (
                                        <Box display="flex" alignItems="center" gap={'10px'}>
                                            <Text fontSize="lg" fontWeight={600}>Xỉu</Text>
                                            <Switch {...field} size="lg" />
                                            <Text fontSize="lg" fontWeight={600}>Tài</Text>
                                        </Box>
                                    )}
                                />
                                <Button
                                    colorScheme="blue"
                                    ml={3}
                                    isLoading={createResultMutation.isPending}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Thêm
                                </Button>
                            </Flex>
                            {/* <Button
                                bg="#3182ce"
                                color="#fff"
                                _hover={{ bg: '#67a1d7' }}
                                onClick={onOpenCreateModal}
                            >
                                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                                    Thêm
                                </Text>
                            </Button> */}
                        </Flex>
                    </CardHeader>
                    <CardBody overflowX="auto">
                        <Stack overflow={'auto'}>
                            <ResultTable data={resultData?.data || []} refetch={refetch} />
                        </Stack>
                        <Flex justifyContent={'flex-end'}>
                            {!isEmpty(resultData?.data) && (
                                <Pagination
                                    page={resultData?.pagination?.page}
                                    pageLength={resultData?.pagination?.pageSize}
                                    totalRecords={resultData?.pagination?.count}
                                    onPageChange={(page, pageLength) => {
                                        setFilter({
                                            ...filter,
                                            pageSize: pageLength,
                                            pageIndex: page - 1,
                                        });
                                    }}
                                />
                            )}
                        </Flex>
                    </CardBody>
                </Card>
                {isCreateModalOpen && <CreatePredictResultModal roomId={id} isOpen={isCreateModalOpen} onClose={handleCloseModal} refetch={refetch} />}
            </Flex>
        </>
    );
}
