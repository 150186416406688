import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react'
import { ModalType } from 'constants/common';
const ModalViewDetailMember = ({ isOpen, onClose, memberDetail }) => {
  const cancelRef = React.useRef();

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Chi tiết thành viên</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <label >Tên</label>
            <Input value={memberDetail?.username} readOnly mb={2} />
            <label >Tên đầy đủ</label>
            <Input value={memberDetail?.fullname} readOnly mb={2} />
            <label >Số điện thoại</label>
            <Input value={memberDetail?.gender} readOnly mb={2} />
            <label >telegram</label>
            <Input value={memberDetail?.telegram} readOnly mb={2} />
            <label >Nhà ccais</label>
            <Input value={memberDetail?.bookMaker} readOnly mb={2} />
            <label >Trạng thái</label>
            <Input value={memberDetail?.bookMaker} readOnly mb={2} />
            <label >Xu</label>
            <Input value={memberDetail?.bookMaker} readOnly mb={2} />
            <label >Chặn</label>
            <Input value={memberDetail?.isBlock} readOnly mb={2} />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Thoát
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalViewDetailMember;
