import React, { useEffect } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Switch,
    Text,
    Box,
    Flex,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { useCreateRoomMutation } from 'services/game';
import InputController from 'components/Form/InputController';

const CreateRoomModal = ({ isOpen, onClose, refetch, gameId }) => {
    const cancelRef = React.useRef();
    const createRoomMutation = useCreateRoomMutation();
    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            name: "",
            gameId: gameId
        },
    });

    const handleSuccess = () => {
        toast.showMessageSuccess(`Thêm bàn chơi thành công`);
        refetch?.();
        onClose(ModalType.Add);
    };

    const handleError = error => {
        toast.showMessageError(
            error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Thêm bàn chơi thất bại`
        );
    };
    const onSubmit = values => {
        createRoomMutation.mutate(
            values,
            {
                onSuccess: () => handleSuccess(),
                onError: error => handleError(error),
            }
        );
    };
    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    onClose(ModalType.Add);
                }}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent maxW={'400px'} maxH={'400pxs'} >
                    <AlertDialogHeader textTransform="uppercase">Thêm bàn chơi</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody >
                        <InputController control={control} name="name" label="Tên bàn chơi" />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                onClose(ModalType.Add);
                            }}
                        >
                            Huỷ bỏ
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            isLoading={createRoomMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Thêm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default CreateRoomModal;