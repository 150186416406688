import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, RoleOption } from 'constants/common';
import { toast } from 'components/Toast';
import { useCreateUserByRoleMutation } from 'services/user';
import { UserFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectController from 'components/Form/SelectController';

const CreateModal = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createUserByRoleMutation = useCreateUserByRoleMutation();
  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(UserFormValidate),
    defaultValues: {
      username: '',
      // phone: '',
      // email: '',
      role: RoleOption[0]
    },
  });

  const handleSuccess = () => {
    toast.showMessageSuccess(`Thêm quản trị viên thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Thêm quản trị viên thất bại`
    );
  };
  const handleRoleSelect = (e) => {
    setValue('role', e)
  }
  const onSubmit = async values => {
    createUserByRoleMutation.mutate(
      { ...values, role: values.role.value },
      {
        onSuccess: () => handleSuccess(),
        // onError: error => handleError(error),
      }
    );
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'} >
          <AlertDialogHeader textTransform="uppercase">Thêm quản trị viên</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <InputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="username" label="Tên" />
            {/* <InputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="phone" label="Số điện thoại" /> */}
            {/* <InputController style={{ marginBottom: '4px', marginTop: '4px' }} control={control} name="email" label="Email" /> */}
            <SelectController
              control={control}
              name="role"
              label="Vai trò"
              isClearable
              options={RoleOption}
              onChange={handleRoleSelect}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Huỷ bỏ
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createUserByRoleMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Thêm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateModal;