import React, { useEffect } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Switch,
    Text,
    Box,
    Flex,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { useCreateResultMutation } from 'services/game';

const CreatePredictResultModal = ({ isOpen, onClose, refetch, roomId }) => {
    const cancelRef = React.useRef();
    const createResultMutation = useCreateResultMutation();
    const { control, handleSubmit, setValue, reset } = useForm({
        defaultValues: {
            roomId: roomId,
            result: {
                tai: false,
                xiu: false
            },
        },
    });

    const handleSuccess = () => {
        toast.showMessageSuccess(`Thêm dự đoán thành công`);
        refetch?.();
        onClose(ModalType.Add);
    };

    const handleError = error => {
        toast.showMessageError(
            error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Thêm dự đoán thất bại`
        );
    };
    const handleRoleSelect = (e) => {
        setValue('role', e)
    }
    const onSubmit = values => {
        const dataSubmit = {
            ...values,
            result: {
                tai: values.result.xiu,
                xiu: !values.result.xiu,
            },
        };
        createResultMutation.mutate(
            dataSubmit,
            {
                onSuccess: () => handleSuccess(),
                onError: error => handleError(error),
            }
        );
    };
    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    onClose(ModalType.Add);
                }}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent maxW={'400px'} maxH={'400pxs'} >
                    <AlertDialogHeader textTransform="uppercase">Thêm dự đoán</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody >
                        <Flex alignItems="center" justifyContent="center" gap={10}>
                            <Controller
                                control={control}
                                name="result.xiu"
                                render={({ field }) => (
                                    <Box display="flex" alignItems="center" gap={'10px'}>
                                        <Text fontSize="lg" fontWeight={600}>Xỉu</Text>
                                        <Switch {...field} size="lg" />
                                        <Text fontSize="lg" fontWeight={600}>Tài</Text>
                                    </Box>
                                )}
                            />
                        </Flex>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                onClose(ModalType.Add);
                            }}
                        >
                            Huỷ bỏ
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            isLoading={createResultMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Thêm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default CreatePredictResultModal;