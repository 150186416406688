import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';
//game
export const getListGames = (bookMakerId, params) => request.get(ROOT_API + `/api/v1/games-in-bo/${bookMakerId}`, { params });
export const getDetailBookMaker= (id) => request.get(ROOT_API + `/api/v1/book-markers/${id}`);
export const createGame = data => request.post(ROOT_API + '/api/v1/games/create', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const createBookMaker = data => request.post(ROOT_API + '/api/v1/book-markers/create', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const updateBookMaker = data => request.post(ROOT_API + '/api/v1/book-markers/update', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
export const getDetailGameRoom = id => request.get(ROOT_API + `/api/v1/game-room/${id}`);


export const updateGame = data => request.post(ROOT_API + '/api/v1/games/update', data,);
export const deleteGame = data => request.post(ROOT_API + '/api/v1/games/delete', data);
//bookMaker
export const getListBookMaker = (params) => request.get(ROOT_API + '/api/v1/book-markers-in-bo', { params });
export const deleteBookMaker = (data) => request.post(ROOT_API + '/api/v1/book-markers/delete', data);
//room
export const getListRooms = (id, params) => request.get(ROOT_API + `/api/v1/rooms-in-bo/${id}`, { params });
export const deleteRoom = data => request.post(ROOT_API + '/api/v1/rooms/delete', data);

export const getListResult = (id, params) => request.get(ROOT_API + `/api/v1/rooms-result/${id}`, { params });

export const createResult = (data) => request.post(ROOT_API + '/api/v1/rooms-result', data);
export const createRoom = (data) => request.post(ROOT_API + '/api/v1/rooms/create', data);

export const changeStatus = (data) => request.post(ROOT_API + '/api/v1/rooms-result/change-status', data);
//game room
export const getListGameRoom = (params) => request.get(ROOT_API + '/api/v1/game-room', { params });
export const deleteGameRoom = (data) => request.post(ROOT_API + '/api/v1/game-room/delete', data);

export const createGameRoom = data =>
  request.post(ROOT_API + '/api/v1/game-room/create', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const updateGameRoom = data =>
  request.post(ROOT_API + '/api/v1/game-room/update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getListCode = params => request.get(ROOT_API + '/api/v1/codes', { params });

// Mutation
export const useCreateGameMutation = () => useMutation({ mutationFn: createGame });
export const useUpdateGameMutation = () => useMutation({ mutationFn: updateGame });
export const useDeleteGameMutation = () => useMutation({ mutationFn: deleteGame });
export const useChangeStatusMutation = () => useMutation({ mutationFn: changeStatus });
export const useCreateResultMutation = () => useMutation({ mutationFn: createResult });
export const useCreateRoomMutation = () => useMutation({ mutationFn: createRoom });
export const useCreateBookMakerMutation = () => useMutation({ mutationFn: createBookMaker });
export const useUpdateBookMakerMutation = () => useMutation({ mutationFn: updateBookMaker });


export const useDeleteBookMakerMutation = () => useMutation({ mutationFn: deleteBookMaker });
export const useDeleteGameRoomMutation = () => useMutation({ mutationFn: deleteGameRoom });
export const useDeleteRoomMutation = () => useMutation({ mutationFn: deleteRoom });

export const createCode = data => request.post(ROOT_API + '/api/v1/codes/create', data);
export const deleteCode = data => request.post(ROOT_API + '/api/v1/codes/delete', data);
export const dropCode = data => request.post(ROOT_API + '/api/v1/codes/dropCollection', data);


export const deleteUser = data => request.post(ROOT_API + '/api/v1/user/delete', data);

// Query

//game
export const useQueryGetListGames = (bookMakerId, params = {}, options = {}) => useQuery({ queryKey: ['GET_LIST_GAMES', bookMakerId, params], queryFn: () => getListGames(bookMakerId, params), ...options });

export const useQueryGetListResult = (id, params = {}, options = {}) => useQuery({ queryKey: ['GET_LIST_RESULT', id, params], queryFn: () => getListResult(id, params), ...options });
//bookMaker
export const useQueryGetListBookMaker = (params = {}, options = {}) => useQuery({ queryKey: ['GET_LIST_BOOKMAKER', params], queryFn: () => getListBookMaker(params), ...options });

//room
export const useQueryGetListRooms = (id, params = {}, options = {}) => useQuery({ queryKey: ['GET_LIST_ROOMS', id, params], queryFn: () => getListRooms(id, params), ...options });
export const useQueryGetDetailBookMaker = (id, options = {}) => useQuery({ queryKey: ['GET_DETAIL_BOOKMAKER', id], queryFn: () => getDetailBookMaker(id), ...options });
export const useQueryGetDetailGameRoom = (id, options = {}) =>
  useQuery({ queryKey: ['GET_DETAIL_GAME_ROOM', id], queryFn: () => getDetailGameRoom(id), ...options });

//gameRoom
export const useQueryGetListGameRoom = (params = {}, options = {}) => useQuery({ queryKey: ['GET_LIST_GAME_ROOM', params], queryFn: () => getListGameRoom(params), ...options });
export const useCreateGameRoomMutation = () => useMutation({ mutationFn: createGameRoom });
export const useUpdateGameRoomMutation = () => useMutation({ mutationFn: updateGameRoom });

export const useQueryGetListCode = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_CODE', params], queryFn: () => getListCode(params), ...options });
export const useCreateCodeMutation = () => useMutation({ mutationFn: createCode });
export const useDeleteCodeMutation = () => useMutation({ mutationFn: deleteCode });
export const useDropCodeMutation = () => useMutation({ mutationFn: dropCode });
export const useDeleteUserMutation = () => useMutation({ mutationFn: deleteUser });
