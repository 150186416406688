import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getProfile = () => request.get(ROOT_API + '/api/v1/user/profile');
export const updateProfile = async (data) => {
  return await request.post(ROOT_API + '/api/v1/user/change-information', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const createUserByRole = data => request.post(ROOT_API + '/api/v1/user/create-user', data);
export const blockUserByRole = data => request.post(ROOT_API + '/api/v1/user/block-user', data)
export const register = data => request.post(ROOT_API + '/api/v1/user/register', data);
export const changePassword = data => request.post(ROOT_API + '/api/v1/user/change-password', data);
export const login = data => request.post(ROOT_API + '/api/v1/user/login', data);
export const getUsers = (params) => request.get(ROOT_API + '/api/v1/user/users', { params });
export const getDetaiUser = id => request.get(ROOT_API + `/api/v1/user/${id}`)
// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });
export const useLoginMutation = () => useMutation({ mutationFn: login });
export const useChangePasswordMutation = () => useMutation({ mutationFn: changePassword });
export const useUpdateProfileMutation = () => useMutation({ mutationFn: updateProfile });
export const useCreateUserByRoleMutation = () => useMutation({ mutationFn: createUserByRole })
export const useBlockUserByRole = () => useMutation({ mutationFn: blockUserByRole })

// Query
export const useQueryUserProfile = (options = {}) => useQuery({ queryKey: ['getUserProfile'], queryFn: () => getProfile(), ...options });
export const useQueryUsers = (parmas, options = {}) => useQuery({ queryKey: ['getUsers', parmas], queryFn: () => getUsers(parmas), ...options });
export const useQueryGetDetailUser = (id, options = {}) =>
  useQuery({ queryKey: ['getDetaiUser', id], queryFn: () => getDetaiUser(id), ...options });