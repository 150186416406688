import React, { useEffect, useState } from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Input } from '@chakra-ui/react'
import { ModalType } from 'constants/common';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { useCreateCoinMutation } from 'services/member';
import { toast } from 'components/Toast';
const ModalAddCoin = ({ isOpen, onClose, refetch, id }) => {
    const cancelRef = React.useRef();
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            balance: ""
        }
    })
    const createCoinMutation = useCreateCoinMutation()
    const handleSuccess = () => {
        toast.showMessageSuccess(`Nạp xu thành công`);
        refetch?.();
        onClose(ModalType.Create);
    };

    const handleError = error => {
        toast.showMessageError(
            error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Nạp xu thất bại`
        );
    };
    const onSubmit = async values => {
        createCoinMutation.mutate(
            { ...values, id },
            {
                onSuccess: () => handleSuccess(),
                onError: error => handleError(error),
            }
        );
    };
    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    onClose(ModalType.Create);
                }}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
                    <AlertDialogHeader textTransform="uppercase">Nạp xu</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <InputController
                            label="Xu"
                            name="balance"
                            control={control}
                        />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={() => {
                                onClose(ModalType.Create);
                            }}
                        >
                            Huỷ bỏ
                        </Button>
                        <Button
                            colorScheme="blue"
                            ml={3}
                            // isLoading={createUserByRoleMutation.isPending}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Thêm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default ModalAddCoin;
