import React from 'react';
import {
  Button,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { ModalType } from 'constants/common';
const ModalViewDetail = ({ isOpen, onClose, data }) => {
  const cancelRef = React.useRef();
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Preview);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} maxH={'400pxs'}>
          <AlertDialogHeader textTransform="uppercase">Thông tin chi tiết</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <div style={{ marginBottom: '8px' }}>
              <label >Tên</label>
              <Input value={data?.username} readOnly />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <label >Số điện thoại</label>
              <Input value={data?.phone} readOnly />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <label >Chặn</label>
              <Input value={data?.isBlock} readOnly />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <label >Vai trò</label>
              <Input value={data?.role} readOnly />
            </div>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Preview);
              }}
            >
              Thoát
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalViewDetail;
